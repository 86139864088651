<template>
    <div>
        <main-nav fixed ref="nav"></main-nav>
        <l-right-contact></l-right-contact>

        <div class="news-detail-container">
            <div class="title">{{ detail['title'] }}</div>
            <div class="news-data">
                <span class="time">{{ detail['created_at']}}</span>
                <span class="view_num">{{ detail['view_num']}}</span>
            </div>
            <div class="news-detail" v-html="detail['content']">
            </div>

            <div class="yg">

                <div v-if="prev" class="pre">
                    <a :href="`/news/${prev.id}`">上一篇：{{ prev['title']}} </a>
                </div>
                <div v-if="next" class="next">
                    <a :href="`/news/${next.id}`">下一篇：{{ next['title']}}</a>
                </div>

            </div>
        </div>

        <l-footer></l-footer>
        <l-icp></l-icp>
    </div>
</template>

<script>
    import MainNav from "../../components/MainNav";
    import LFooter from "../../components/LFooter";
    import LIcp from "../../components/LIcp";
    import LRightContact from "../../components/LRightContact";
    import meta from "../../meta";
    import http from "../../http";

    export default {
        name: "Detail",
        components: {LRightContact, LIcp, LFooter, MainNav},
        metaInfo: meta.appendTitle('颜创动态'),
        data() {
            return {
                detail: {},
                next: {},
                prev: {},
            }
        },
        mounted() {
            this.$refs['nav'].dark()
            this.getData()
        },
        methods: {
            getData() {
                let id = this.$route.params.id

                http.get(`article?id=${id}`, (data) => {

                    this.detail = data.data.detail
                    this.next = data.data.next
                    this.prev = data.data.prev
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .news-detail-container {
        padding-top: 170px;
        text-align: left;
        width: 1200px;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 200px;
    }

    .title {
        margin-bottom: 50px;
        font-size: 48px;
        font-weight: 500;
        color: #333333;
        line-height: 67px;
        letter-spacing: 1px;
    }

    .news-data {

        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        text-align: right;
        padding-bottom: 10px;
        border-bottom: 1px solid #333333;

        span {
            margin-left: 40px;
            display: inline-block;
            padding-left: 30px;
            position: relative;

            &:before {
                content: ' ';
                width: 22px;
                height: 22px;
                left: 0;
                top: 0;
                position: absolute;
            }
        }
    }

    .news-detail {
        padding: 30px 0;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 30px;

        border-bottom: 1px solid #333;
        margin-bottom: 20px;

    }

    .time:before {
        background-image: url("../../assets/images/news/icon-time.png");
        background-position: center;
        background-repeat: no-repeat;
    }

    .view_num:before {
        background-image: url("../../assets/images/news/icon-eyes.png");
        background-position: center;
        background-repeat: no-repeat;
    }

    .yg {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        display: flex;
        justify-content: space-between;

        a {
            color: rgba(51, 51, 51, 1)
        }
    }
</style>